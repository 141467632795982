import React, {useEffect} from 'react';
import {navigate} from 'gatsby';
import Layout from '../../components/layout/layout';


const PricingPromo = () => {
  useEffect(() => {
    navigate('/pricing/');
  }, []);

  return <Layout pricingPromo></Layout>;
};

export default PricingPromo;
